import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Yun = () => {
  return (
    <Layout>
      <Head title="Yun-Fei Ji" />
      <h3>Yun-Fei Ji</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        New York
      </h4>
      <p>
        <OutboundLink href="https://en.wikipedia.org/wiki/Yun-Fei_Ji">
          Wikipedia
        </OutboundLink>
      </p>
      <img
        src="https://www.studiointernational.com/images/articles/y/yun-fei-ji-2016/The-Vendors-and-the-Wind.jpg"
        alt="Yun-Fei Ji ink art"
      />
      <img
        src="https://www.pierogi2000.com/wp/wp-content/uploads/JiYunFeiTheWreckage.jpg"
        alt="Yun-Fei Ji ink art"
      />
      {/* <img
        src="https://upload.wikimedia.org/wikipedia/en/4/46/Detail_from_The_Village_and_its_Ghosts_by_Yun-Fei_Ji.JPG"
        alt="Yun-Fei Ji ink art"
      /> */}
    </Layout>
  );
};

export default Yun;
